import React from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'

import { Flex } from 'rebass'
import Link from '../Link'
import { RowFixed } from '../Row'
import Logo from '../../assets/D502logo.png'

const TitleWrapper = styled.div`
  text-decoration: none;

  &:hover {
    cursor: pointer;
  }

  z-index: 10;
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const StyledText = styled.span`
  color: #ffffff;
  font-family: 'Russo One', sans-serif;
  font-size: 21px;
  letter-spacing: 0.03em;
  margin-left: 17px;
  @media (max-width: 400px) {
    display: none;
  }
`

const NumberText = styled.span`
  font-family: Rajdhani;
  font-weight: bold;
  font-size: 26px;
`

export default function Title() {
  const history = useHistory()

  return (
    <TitleWrapper onClick={() => history.push('/')}>
      <Flex alignItems="center">
        <RowFixed>
          <UniIcon id="link" onClick={() => history.push('/')}>
            <img width={'30px'} src={Logo} alt="logo" />
          </UniIcon>
          <StyledText>
            <NumberText>502</NumberText>swap time machine
          </StyledText>
        </RowFixed>
      </Flex>
    </TitleWrapper>
  )
}
