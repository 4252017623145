import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils'
import PlaceHolder from '../../assets/placeholder.png'
import EthereumLogo from '../../assets/eth.png'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)

  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <Image {...rest} alt={''} src={PlaceHolder} size={size} />
      </Inline>
    )
  }

  let path = `https://raw.githubusercontent.com/trustwallet/assets/master/blockchains/ethereum/assets/${isAddress(
    address
  )}/logo.png`

  // eslint-disable-next-line default-case
  switch (address.toLowerCase()){
    case '0x2cd9324ba13b77554592d453e6364086fbba446a':
      path = "https://d502.io/assets/tokens/ico-z.png"
      break;
    case '0x661b599e21aaa09e3804824f78e99dc469a8876e':
      path = "https://d502.io/assets/tokens/D502logo.png"
      break;
    case '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2':
      path = "https://d502.io/assets/tokens/ethereum.png"
      break;
    case '0xdac17f958d2ee523a2206206994597c13d831ec7':
      path = "https://d502.io/assets/tokens/USDT.png"
      break;
    case '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48':
      path = "https://d502.io/assets/tokens/USDC.png"
      break;
  }

  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={event => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}
